<template>
  <main v-loading="loading" class="mb-[20px]">
    <PointStatisticBlock v-if="data.length" :data="displayData" />
  </main>
</template>

<script setup>
import PointStatisticBlock from '@/components/Report/PointStatisticBlock.vue'
import { FindMemberPointYearExpirations } from '@/api/shopPoint'
import store from '@/store'
import { computed, onMounted, ref, watch } from 'vue'
import { map, reduce } from 'lodash'
import { formatDate } from '@/utils/date'

const props = defineProps({
  memberId: {
    type: String,
  },
  loading: {
    type: Boolean,
  },
  shopPointKey: {
    type: String,
  },
})

const data = ref([])
const shopId = computed(() => store.getters.shop)

const findMemberPointYearExpirations = async () => {
  const [res, err] = await FindMemberPointYearExpirations({
    shopId: shopId.value,
    memberId: props.memberId,
    shopPointKey: props.shopPointKey,
  })
  if (err) {
    window.$message.error(err)
    return
  }
  data.value = res
}

watch(props, async () => {
  if (props.loading) await findMemberPointYearExpirations()
})

onMounted(() => {
  findMemberPointYearExpirations()
})

const displayData = computed(() => {
  if (!data.value.length) return []
  const res = map(data.value, i => {
    return {
      label: `${formatDate(i.expirationDate, 'YYYY/MM/DD')} 到期`,
      value: i.balance,
    }
  })
  res.unshift({
    label: '總點數',
    value: reduce(res, (sum, i) => sum + i.value, 0),
  })

  return res
})

</script>

<style scoped lang="postcss">

</style>
