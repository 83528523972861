<template>
  <main v-loading="loading" class="rebate-tab">
    <template v-if="!showPage">
      <p>尚未註冊</p>
    </template>
    <template v-if="showPage">
      <PointStatistic :memberId="memberId" :loading="loading" :shopPointKey="shopPointKey" />
      <UsePointBlock :memberId="memberId" :pointBalance="pointWalletBalance" :shopPointKey="shopPointKey" @refresh="refresh" />
      <SectionTitle title="點數使用紀錄" hideBtn />
      <BaseTable v-loading="loading" :data="displayData">
        <BaseElTableColumn prop="createdAt" label="建立時間" align="center">
          <template slot-scope="scope">{{ dateFormat(scope.row.createdAt) }}</template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="type" label="形式" align="center">
          <template slot-scope="scope">
            {{ get(originConfig, `${scope.row.origin}.name`) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="pointName" label="點數名稱" align="center" />
        <BaseElTableColumn prop="amount" label="點數" align="center">
          <template slot-scope="scope">
            <p :class="{'text-danger': scope.row.amount < 0}">
              <span>{{ scope.row.amount > 0 ? '+' : '' }}</span>
              <span>{{ scope.row.amount }}</span>
            </p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="useStorePermission" prop="Branch" label="操作單位" align="center">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="originMessage" label="備註" align="center">
          <template slot-scope="scope">{{ scope.row.originMessage || '-' }}</template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        class="pagination"
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableOptions.dataCount"
        @pageChange="pageChange"
      />
    </template>
  </main>
</template>

<script setup>
import { useTable } from '@/use/table'
import { FindShopPoint, FindPointWalletRecord, FindPointWalletRecordCount } from '@/api/shopPoint'
import PointStatistic from './components/PointStatistic.vue'
import { originConfig } from '@/config/point'
import UsePointBlock from './components/UsePointBlock.vue'
import store from '@/store'
import { computed, ref, onMounted } from 'vue'
import { get, map, find } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'

const props = defineProps({
  memberId: {
    type: String,
  },
  pointWalletBalance: {
    type: Number,
  },
  shopPointKey: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['updatePoint'])
const { shopPointList } = useShop()
const { pageStartIndex, dateFormat, tableOptions, tableData } = useTable()
const loading = ref(false)
const showPage = ref(false)
const shopId = computed(() => store.getters.shop)
const { checkAction } = usePermissions()

const useStorePermission = computed(() => {
  return checkAction('admin.branch.adminUseStore')
})

const displayData = computed(() => {
  return map(tableData.value, (item) => {
    return {
      ...item,
      pointName: get(find(shopPointList.value, { key: props.shopPointKey }), 'name', '-'),
    }
  })
})

const findPointWalletRecord = async () => {
  const [res, err] = await FindPointWalletRecord({
    shopId: shopId.value,
    memberId: props.memberId,
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    shopPointKey: props.shopPointKey,
  })
  if (err) return (window.$message.error(err))
  tableData.value = res
}

const findPointWalletRecordCount = async () => {
  const [res, err] = await FindPointWalletRecordCount({
    shopId: shopId.value,
    memberId: props.memberId,
    shopPointKey: props.shopPointKey,
  })
  if (err) return (window.$message.error(err))
  tableOptions.dataCount = res.count
}

const pageChange = async () => {
  await refresh()
}

const refresh = async () => {
  loading.value = true
  await findShopPoint()
  if (!showPage.value) return
  await Promise.all([
    findPointWalletRecord(),
    findPointWalletRecordCount(),
  ])
  emit('updatePoint')
  loading.value = false
}

const findShopPoint = async () => {
  const [res, err] = await FindShopPoint({ shopId: shopId.value })
  if (err) return (window.$message.error(err))
  if (res.id) showPage.value = true
  else loading.value = false
}

onMounted(async () => {
  await refresh()
})

</script>

<style scoped lang="postcss">

</style>
