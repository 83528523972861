export const entryStatusConfig = {
  entered: { label: '已進場', value: 'entered', tagType: 'warning' },
  exited: { label: '已出場', value: 'exited', tagType: 'info' },
}

export const exitOriginConfig = {
  client: { label: '顧客自行出場', value: 'client' },
  admin: { label: '後台操作', value: 'admin' },
}

export const entryFilterStatusConfig = {
  entered: { label: '尚未出場', value: 'entered', tagType: 'warning' },
  exited: { label: '已出場', value: 'exited', tagType: 'info' },
  manual: { label: '手動出場', value: 'null', tagType: 'info' },
}

export const entitlementTypeConfig = {
  punchCard: { label: '次卡', value: 'punchCard' },
  pointCard: { label: '點數卡', value: 'pointCard' },
  admin: { label: '後台新增', value: 'admin' },
}

export const entitlementTypeUnitConfig = {
  punchCard: { label: '次', value: 'punchCard' },
  pointCard: { label: '點', value: 'pointCard' },
}

export const entitlementStartDetailErrorConfig = {
  BRANCH_NOT_MATCH: { label: '門市不符', value: 'BRANCH_NOT_MATCH' },
  WEEK_NOT_MATCH: { label: '星期不符', value: 'WEEK_NOT_MATCH' },
  PERIOD_NOT_MATCH: { label: '時段不符', value: 'PERIOD_NOT_MATCH' },
  AMOUNT_NOT_ENOUGH: { label: '庫存不足', value: 'AMOUNT_NOT_ENOUGH' },
}
