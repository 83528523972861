<template>
  <div class="wallet-tab">
    <section>
      <div v-loading="loading.table" class="use" style="margin-top: 20px">
        <BaseElForm
          ref="formRef"
          :model="formData"
          :rules="formRules"
          label-width="120px"
          label-position="left"
        >
          <BaseElFormItem label="操作" prop="walletAction">
            <BaseElRadioGroup v-model="formData.walletAction">
              <BaseElRadio v-for="item in noteCodeSearchOptions" :key="item.value" :label="item.value">{{ item.label }}</BaseElRadio>
            </BaseElRadioGroup>
          </BaseElFormItem>
          <BaseElFormItem label="金額" prop="coin">
            <BaseElInput v-model="formData.coin" />
          </BaseElFormItem>
          <BaseElFormItem label="備註" prop="note">
            <BaseElInput v-model="formData.note" />
          </BaseElFormItem>
          <BaseElFormItem>
            <BaseElButton plain @click="clearForm">
              取消
            </BaseElButton>
            <BaseElButton type="primary" @click="handleWallet">儲存</BaseElButton>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
    <template>
      <SectionTitle hideBtn>
        <template slot="title">
          <p style="font-size: 24px">{{externalWalletName}}使用紀錄</p>
          <p class="text-gray-60 text-sub" style="margin-left: 12px">下方紀錄非即時同步</p>
        </template>
      </SectionTitle>
      <BaseTable
        v-loading="loading.table"
        :data="displayData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="type" label="形式" align="center">
          <template slot-scope="scope">
              <p>{{ convertNoteCode(scope.row.type) }}</p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="amount" :label="externalWalletName" align="center" />
        <BaseElTableColumn prop="note" label="備註" align="center" show-overflow-tooltip />
        <BaseElTableColumn prop="recordTime" label="建立時間" width="120" align="center" />
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { noEmptyRules, isDigitRules } from '@/validation/index'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  noteCodeOptions,
  noteCodeSearchOptions,
} from '@/config/externalWallet'
import { GetExternalWalletRecord, GetExternalWalletRecordCount, UseExternalWallet, DepositExternalWallet } from '@/api/externalWallet'
import debounce from 'lodash.debounce'
import { formatDate } from '@/utils/date'
import { useBaseForm } from '@/use/useForm'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useExternalWallet } from '@/use/useExternalWallet'

export default defineComponent({
  name: 'ExternalWalletTab',
  components: { EmptyBlock },
  props: ['member'],
  setup(props, { emit }) {
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const { formData, formRef, initFormData, checkForm } = useBaseForm()
    const { tableData, tableDataCount, tableOptions, fetchData, fetchDataCount, loading, shopId, pageStartIndex } = useTable()
    initFormData({
      walletAction: '',
      note: '',
      coin: '',
    })
    const formRules = {
      coin: [isDigitRules(), noEmptyRules()],
      walletAction: [noEmptyRules()],
    }
    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        return {
          type: item.type,
          amount: item.amount || '-',
          note: item.note || '-',
          recordTime: item.recordTime ? formatDate(item.recordTime) : '-',
        }
      })
    })
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        memberId: get(props, 'member.id'),
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetExternalWalletRecord, payload),
        fetchDataCount(GetExternalWalletRecordCount, payload),
      ])
    }
    const refresh = async () => {
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const handleWallet = debounce(async () => {
      loading.value = true
      const isValid = await checkForm(formRef.value)
      if (!isValid) return
      const payload = {
        shopId: shopId.value,
        memberId: get(props, 'member.id'),
        amount: formData.coin,
        note: formData.note === '' ? undefined : formData.note,
      }
      if (formData.walletAction === 'ADMIN_ADD') await depositExternalWallet(payload)
      else await withdrawExternalWallet(payload)
      loading.value = false
    }, 300, { leading: false, trailing: true })

    const depositExternalWallet = async (payload) => {
      const [, err] = await DepositExternalWallet(payload)
      if (err) {
        window.$message.error(err)
        return
      }
      formData.coin = ''
      formData.note = ''
      window.$message.success(`已儲值${externalWalletName.value}!`)
      emit('updateWallet')
      await refresh()
    }
    const withdrawExternalWallet = async (payload) => {
      const [, err] = await UseExternalWallet(payload)
      if (err) {
        window.$message.error(err)
        return
      }
      formData.coin = ''
      formData.note = ''
      window.$message.success(`已使用${externalWalletName.value}!`)
      emit('updateWallet')
      await refresh()
    }
    const convertNoteCode = (type) => {
      return get(noteCodeOptions[type], 'label')
    }
    const clearForm = async () => {
      initFormData({
        walletAction: '',
        note: '',
        coin: '',
      })
    }
    onMounted(async() => {
      await refresh()
      await getExternalWalletConfig()
    })
    return {
      formatDate,
      formData,
      formRef,
      formRules,
      tableData,
      tableDataCount,
      loading,
      tableOptions,
      handleWallet,
      convertNoteCode,
      clearForm,
      noteCodeSearchOptions,
      displayData,
      externalWalletName,
      refresh,
    }
  },
})
</script>

<style scoped lang="scss"></style>
