export const noteCodeOptions = {
  ADMIN_ADD: {
    label: '後台儲值',
    value: 'ADMIN_ADD',
  },
  APPOINTMENT_DEPOSIT_RETURN: {
    label: '預約訂金返還',
    value: 'APPOINTMENT_DEPOSIT_RETURN',
  },
  APPOINTMENT_CHECKOUT_CANCEL: {
    label: '取消預約結帳使用',
    value: 'APPOINTMENT_CHECKOUT_CANCEL',
  },
  MEMBER_STORE_ADD: {
    label: '會員商城新增',
    value: 'MEMBER_STORE_ADD',
  },
  ECOMERCE_CANCEL: {
    label: '取消電商使用',
    value: 'ECOMERCE_CANCEL',
  },
  POS_ADD: {
    label: 'POS儲值',
    value: 'POS_ADD',
  },
  ADMIN_USE: {
    label: '後台使用',
    value: 'ADMIN_USE',
  },
  APPOINTMENT_DEPOSIT_USE: {
    label: '預約訂金使用',
    value: 'APPOINTMENT_DEPOSIT_USE',
  },
  APPOINTMENT_CHECKOUT_USE: {
    label: '預約結帳使用',
    value: 'APPOINTMENT_CHECKOUT_USE',
  },
  ECOMERCE_USE: {
    label: '電商使用',
    value: 'ECOMERCE_USE',
  },
  MEMBER_STORE_USE: {
    label: '會員商城使用',
    value: 'MEMBER_STORE_USE',
  },
  MEMBER_STORE_ADD_CANCEL: {
    label: '取消商城新增',
    value: 'MEMBER_STORE_ADD_CANCEL',
  },
  POS_USE: {
    label: 'POS扣除',
    value: 'POS_USE',
  },
}
export const noteCodeSearchOptions = [
  {
    label: '使用',
    value: 'ADMIN_USE',
  },
  {
    label: '儲值',
    value: 'ADMIN_ADD',
  },
]

export const externalWalletSourceConfig = {
  posPal: { label: '瑞乘', value: 'posPal' },
}
